import React from "react"

const Declaration = () => {
  return (
    <p>
      Views are personal. All link references are kept to self learn only.
    </p>
  )
}

export default Declaration
